import React, { createContext, useContext, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from '@apollo/client'
import sha256 from 'crypto-js/sha256'
import Base64 from 'crypto-js/enc-base64'
import atob from 'atob'

import {
  SIGN_UP_MUTATION,
  LOG_IN_MUTATION,
  LOG_OUT_MUTATION,
  RESET_PASSWORD_MUTATION,
  CHANGE_PASSWORD_MUTATION,
  REQUEST_PASSWORD_RESET_MUTATION
} from '../mutations/authentication'
import { SESSION } from '../queries/account'

export const isBrowser = () => typeof window !== 'undefined'

export const getJWT = () => isBrowser() ? window.localStorage.getItem('ls_jwt') : null

export const setJWT = jwt => jwt === null ? window.localStorage.removeItem('ls_jwt') : window.localStorage.setItem('ls_jwt', jwt)

const parseJWT = (jwt) => {
  const base64Url = jwt.split('.')[1]
  return JSON.parse(atob(base64Url))
}

const getUser = () => {
  const jwt = getJWT()
  if (!jwt) {
    return null
  }
  return parseJWT(jwt)
}

const UserContext = createContext({
  isAuthenticated: () => {},
  getUser,
  useSignUp: () => {},
  useLogOut: () => {},
  useLogIn: () => {},
  useChangePassword: () => {},
})

export const useUserContext = () => useContext(UserContext)

export const UserProvider = ({ children }) => {

  const identifyUser = user => {
    if (window.__insp) {
      window.__insp.push(['identify', user.email])
    }
    if (window.FS) {
      window.FS.identify(user.id)
      window.FS.setUserVars({
        displayName: `${user.first_name} ${user.last_name}`,
        email: user.email
      })
    }
  }

  const useLogOut = () => {
    const [logOut, logOutState] = useMutation(LOG_OUT_MUTATION)
    const { loading, error } = logOutState
    const logOutFn = () => logOut()

    useEffect(() => {
      if (logOutState?.data?.logOut?.success) {
        setJWT(null)
      }
    }, [loading, error])
    return [logOutFn, logOutState]
  }

  const useLogIn = () => {
    const [logIn, logInState] = useMutation(LOG_IN_MUTATION)
    const { loading, error } = logInState

    const logInFn = (email, password) => {
      const hash = Base64.stringify(sha256(password))
      return logIn({ variables: { email, password: hash } })
    }

    useEffect(() => {
      const me = logInState?.data?.logIn?.me
      if (!(loading || error) && me) {
        identifyUser(me)
        setJWT(me?.token)
      }
    }, [loading, error])

    return [logInFn, logInState]
  }

  const useSignUp = () => {
    const [signUp, signUpState] = useMutation(SIGN_UP_MUTATION)
    const { loading, error } = signUpState

    const signUpFn = (
      email,
      firstName,
      lastName,
      phoneNumber,
      address,
      password,
      passwordConfirmation
    ) => {
      const hash = Base64.stringify(sha256(password))
      const hash2 = Base64.stringify(sha256(passwordConfirmation))
      return signUp({
        variables: {
          email,
          firstName,
          lastName,
          phoneNumber,
          address,
          password: hash,
          passwordConfirmation: hash2
        }
      })
    }

    useEffect(() => {
      const me = signUpState?.data?.signUp?.me
      if (!(loading || error) && me) {
        identifyUser(me)
        setJWT(me?.token)
      }
    }, [loading, error])

    return [signUpFn, signUpState]
  }

  const useChangePassword = () => {
    const [changePassword, changePasswordState] = useMutation(CHANGE_PASSWORD_MUTATION)

    const changePasswordFn = (
      currentPassword,
      password,
      passwordConfirmation
    ) => {
      const values = { currentPassword, password, passwordConfirmation }
      const variables = {}
      for (const key in values) {
        const hash = Base64.stringify(sha256(values[key]))
        variables[key] = hash
      }
      return changePassword({
        variables
      })
    }

    return [changePasswordFn, changePasswordState]
  }

  const useRequestPasswordReset = () => {
    const [requestPasswordReset, requestPasswordResetState] = useMutation(REQUEST_PASSWORD_RESET_MUTATION)

    const requestPasswordResetFn = (email) => requestPasswordReset({
      variables: { email }
    })

    return [requestPasswordResetFn, requestPasswordResetState]
  }

  const useResetPassword = () => {
    const [resetPassword, resetPasswordState] = useMutation(RESET_PASSWORD_MUTATION)

    const resetPasswordFn = (
      unhashedPassword,
      unhashedPasswordConfirmation,
      resetPasswordToken
    ) => {
      const password = Base64.stringify(sha256(unhashedPassword))
      const passwordConfirmation = Base64.stringify(sha256(unhashedPasswordConfirmation))
      return resetPassword({
        variables: {
          password,
          passwordConfirmation,
          resetPasswordToken
        }
      })
    }

    return [resetPasswordFn, resetPasswordState]
  }

  const { loading, error, data } = useQuery(SESSION, { ssr: false })
  const isExpired = (jwt) => {
    if (jwt) {
      const token = parseJWT(jwt)
      const now = new Date().getTime()

      return token.exp * 1000 < now
    } else {
      return false
    }
  }

  const isAuthenticated = useCallback(()=> {
    function getStatus() {
      const jwt = getJWT()
      return !!jwt && !isExpired(jwt)
    }
    return getStatus()
  }, [loading, getJWT()])

  useEffect(() => {
    if (data?.session === false || error || isExpired(getJWT())) {
      setJWT(null)
    }
  }, [data])

  return (
    <UserContext.Provider value={{
      isAuthenticated,
      getUser,
      useSignUp,
      useLogOut,
      useLogIn,
      useChangePassword,
      useRequestPasswordReset,
      useResetPassword
    }}>
      {children}
    </UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]).isRequired
}
