
import { ApolloClient, ApolloLink, from } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import fetch from 'isomorphic-fetch'

import env from '../env'
import { getJWT, setJWT } from '../providers/user'

const uploadLink = createUploadLink({ uri: `${env.API_URL}/graphql`, fetch })

const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const token = getJWT()
  const authorization = token ? `Bearer ${token}` : null
  const headers = {}
  if (authorization) {
    headers.authorization = authorization
  }
  operation.setContext({ headers })
  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => console.error(
      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
    ))
  }
  if (networkError) {
    if (networkError.statusCode === 401) {
      return setJWT(null)
    }
    console.error(`[Network error]: ${networkError}`)
  }
})

const client = new ApolloClient({
  link: from([errorLink, middlewareAuthLink, uploadLink]),
  cache: new InMemoryCache()
})

export default client
