import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

export const ModalContext = createContext()

export const ModalProvider = ({ children, component }) => {
  const [modalOpen, toggleModal] = useState(false)
  return (
    <ModalContext.Provider value={{ toggleModal, modalOpen, component }}>
      {children}
    </ModalContext.Provider>
  )
}

ModalProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]).isRequired,
  component: PropTypes.object
}
