import React, { useState, useEffect } from 'react'

export const useViewportWidth = () => {
  const isSSR = () => typeof window === 'undefined'
  const [viewportWidth, setViewportWidth] = useState(!isSSR() && window.innerWidth >= 992)
  useEffect(() => {
    if (!isSSR()) {
      const toggleDesktop = () => setViewportWidth(window.innerWidth)
      toggleDesktop() // initial render
      window.addEventListener('resize', toggleDesktop)
      // clean up event listener
      return _ => window.removeEventListener('resize', toggleDesktop)
    }
  })
  return viewportWidth
}
