// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-layout-account-layout-jsx": () => import("./../../../src/pages/account/layout/AccountLayout.jsx" /* webpackChunkName: "component---src-pages-account-layout-account-layout-jsx" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-covid-19-updates-index-js": () => import("./../../../src/pages/covid-19-updates/index.js" /* webpackChunkName: "component---src-pages-covid-19-updates-index-js" */),
  "component---src-pages-customer-services-corporate-js": () => import("./../../../src/pages/customer-services/corporate.js" /* webpackChunkName: "component---src-pages-customer-services-corporate-js" */),
  "component---src-pages-customer-services-delivery-js": () => import("./../../../src/pages/customer-services/delivery.js" /* webpackChunkName: "component---src-pages-customer-services-delivery-js" */),
  "component---src-pages-customer-services-index-js": () => import("./../../../src/pages/customer-services/index.js" /* webpackChunkName: "component---src-pages-customer-services-index-js" */),
  "component---src-pages-customer-services-payment-js": () => import("./../../../src/pages/customer-services/payment.js" /* webpackChunkName: "component---src-pages-customer-services-payment-js" */),
  "component---src-pages-customer-services-pick-up-js": () => import("./../../../src/pages/customer-services/pick-up.js" /* webpackChunkName: "component---src-pages-customer-services-pick-up-js" */),
  "component---src-pages-customer-services-virtual-repair-js": () => import("./../../../src/pages/customer-services/virtual-repair.js" /* webpackChunkName: "component---src-pages-customer-services-virtual-repair-js" */),
  "component---src-pages-estimates-delivery-options-desktop-jsx": () => import("./../../../src/pages/estimates/DeliveryOptions/Desktop.jsx" /* webpackChunkName: "component---src-pages-estimates-delivery-options-desktop-jsx" */),
  "component---src-pages-estimates-delivery-options-mobile-jsx": () => import("./../../../src/pages/estimates/DeliveryOptions/Mobile.jsx" /* webpackChunkName: "component---src-pages-estimates-delivery-options-mobile-jsx" */),
  "component---src-pages-estimates-index-js": () => import("./../../../src/pages/estimates/index.js" /* webpackChunkName: "component---src-pages-estimates-index-js" */),
  "component---src-pages-forgot-password-index-js": () => import("./../../../src/pages/forgot-password/index.js" /* webpackChunkName: "component---src-pages-forgot-password-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-licensed-stores-index-js": () => import("./../../../src/pages/licensed-stores/index.js" /* webpackChunkName: "component---src-pages-licensed-stores-index-js" */),
  "component---src-pages-locations-index-js": () => import("./../../../src/pages/locations/index.js" /* webpackChunkName: "component---src-pages-locations-index-js" */),
  "component---src-pages-log-in-index-js": () => import("./../../../src/pages/log-in/index.js" /* webpackChunkName: "component---src-pages-log-in-index-js" */),
  "component---src-pages-partnerships-index-js": () => import("./../../../src/pages/partnerships/index.js" /* webpackChunkName: "component---src-pages-partnerships-index-js" */),
  "component---src-pages-repair-services-index-js": () => import("./../../../src/pages/repair-services/index.js" /* webpackChunkName: "component---src-pages-repair-services-index-js" */),
  "component---src-pages-repair-wizard-index-js": () => import("./../../../src/pages/repair-wizard/index.js" /* webpackChunkName: "component---src-pages-repair-wizard-index-js" */),
  "component---src-pages-sign-up-index-js": () => import("./../../../src/pages/sign-up/index.js" /* webpackChunkName: "component---src-pages-sign-up-index-js" */),
  "component---src-pages-start-a-repair-index-js": () => import("./../../../src/pages/start-a-repair/index.js" /* webpackChunkName: "component---src-pages-start-a-repair-index-js" */),
  "component---src-pages-start-a-repair-repair-intro-jsx": () => import("./../../../src/pages/start-a-repair/RepairIntro.jsx" /* webpackChunkName: "component---src-pages-start-a-repair-repair-intro-jsx" */),
  "component---src-pages-sustainability-index-js": () => import("./../../../src/pages/sustainability/index.js" /* webpackChunkName: "component---src-pages-sustainability-index-js" */),
  "component---src-templates-customer-services-page-js": () => import("./../../../src/templates/customer-services-page.js" /* webpackChunkName: "component---src-templates-customer-services-page-js" */),
  "component---src-templates-location-page-js": () => import("./../../../src/templates/location-page.js" /* webpackChunkName: "component---src-templates-location-page-js" */),
  "component---src-templates-policy-page-js": () => import("./../../../src/templates/policy-page.js" /* webpackChunkName: "component---src-templates-policy-page-js" */),
  "component---src-templates-press-page-js": () => import("./../../../src/templates/press-page.js" /* webpackChunkName: "component---src-templates-press-page-js" */),
  "component---src-templates-repair-item-page-js": () => import("./../../../src/templates/repair-item-page.js" /* webpackChunkName: "component---src-templates-repair-item-page-js" */),
  "component---src-templates-repair-service-page-js": () => import("./../../../src/templates/repair-service-page.js" /* webpackChunkName: "component---src-templates-repair-service-page-js" */),
  "component---src-templates-testimonial-page-js": () => import("./../../../src/templates/testimonial-page.js" /* webpackChunkName: "component---src-templates-testimonial-page-js" */)
}

