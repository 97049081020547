import React, { createContext, useContext } from 'react'
import { useViewportWidth } from '~hooks/viewport'

const MediaContext = createContext({
  isDesktop: () => false
})

export const useMediaContext = () => useContext(MediaContext)

export const MediaProvider = ({ children }) => {
  const viewportWidth = useViewportWidth()
  return (
    <MediaContext.Provider value={{ isDesktop: () => viewportWidth > 992 }}>
      {children}
    </MediaContext.Provider>
  )
}
