import gql from 'graphql-tag'

export const ADDRESS_QUERY = gql`{
  myAddresses {
    id
    longitude
    latitude
    street
    addressLine2
    city
    state
    stateCode
    postalCode
    country
    countryCode
    type
    phoneNumber
  }
}`

export const PROFILE_QUERY = gql`{
  me {
    firstName
    lastName
    gender
    birthday
    favoriteBrand
    phoneNumber
    addresses {
      id
      longitude
      latitude
      street
      addressLine2
      city
      state
      stateCode
      postalCode
      country
      countryCode
      type
    }
  }
}`

export const SESSION = gql`{
  session
}`