import gql from 'graphql-tag'

export const SIGN_UP_MUTATION = gql`
  mutation SignUp (
    $email: String!
    $password: String!
    $passwordConfirmation: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $address: AddressInput!
  ) {
    signUp(input: {
      email: $email,
      password: $password,
      passwordConfirmation: $passwordConfirmation,
      firstName: $firstName,
      lastName: $lastName,
      phoneNumber: $phoneNumber,
      address: $address
    }) {
      me {
        id
        email
        name
        firstName
        lastName
        token
      }
      errors
    }
  }
`

export const LOG_IN_MUTATION = gql`
  mutation LogIn (
    $email: String!
    $password: String!
  ) {
    logIn(input: {
      email: $email,
      password: $password,
    }) {
      me {
        id
        email
        name
        firstName
        lastName
        token
      }
    }
  }
`

export const LOG_OUT_MUTATION = gql`
  mutation LogOut {
    logOut(input: {}) {
      success
    }
  }
`

export const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation RequestPasswordReset (
    $email: String!
    ) {
    requestPasswordReset(input: {
      email: $email
    }) {
      success
    }
  }
`

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword (
    $password: String!,
    $passwordConfirmation: String!
    $resetPasswordToken: String!
  ) {
    resetPassword(input: {
      password: $password,
      passwordConfirmation: $passwordConfirmation,
      resetPasswordToken: $resetPasswordToken
    }) {
      success
      errors
    }
  }
`

export const REQUEST_EMAIL_CONFIRMATION_MUTATION = gql`
  mutation RequestEmailConfirmation ($email: String!) {
    requestEmailConfirmation(input: {
      email: $email
    }) {
      success
    }
  }
`

export const CONFIRM_EMAIL_MUTATION = gql`
  mutation ConfirmEmail ($confirmationToken: String!) {
    confirmEmail(input: {
      confirmationToken: $confirmationToken
    }) {
      success
    }
  }
`

export const CHANGE_EMAIL_MUTATION = gql`
  mutation ChangeEmail ($email: String!, $password: String!) {
    changeEmail(input: {
      email: $email,
      password: $password
    }) {
      success
    }
  }
`

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword (
    $currentPassword: String!,
    $password: String!,
    $passwordConfirmation: String!
  ) {
    changePassword(input: {
      currentPassword: $currentPassword,
      password: $password,
      passwordConfirmation: $passwordConfirmation
    }) {
      success
    }
  }
`

export const SUBSCRIBE_MUTATION = gql`
  mutation Subscribe (
    $email: String!,
    $list: String!
  ) {
    subscribe(input: {
      email: $email,
      list: $list
    }) {
      success
    }
  }
`
