import React from 'react'
import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'

import client from './src/components/ApolloClient'
import { ModalProvider } from './src/providers/ModalContext'
import { UserProvider } from './src/providers/user'
import { MediaProvider } from './src/providers/media'

export const onClientEntry = function (_, pluginParams) {
  window.Sentry = Sentry
}

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  <ModalProvider>
    <ApolloProvider client={client}>
      <UserProvider>
        <MediaProvider>
          {element}
        </MediaProvider>
      </UserProvider>
    </ApolloProvider>
  </ModalProvider>
)
